import "./CSS/tunes.css";
import Music from "./Music";

import LOML from "./Assets/Images/Covers/LOML.png";
import Bestfriend from "./Assets/Images/Covers/Bestfriend.png";
import LatelyMaybe from "./Assets/Images/Covers/LatelyMaybe.png";
import WFY from "./Assets/Images/Covers/WFY.png";
import EasyComeEasyGo from "./Assets/Images/Covers/EasyComeEasyGo.png";

import { motion } from "framer-motion";

function Tunes() {
  return (
    <motion.section
      className="tunes"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.15 } }}>
      <div className="tunes_bg">
        <p>TUNES</p>
      </div>

      <div className="tunes_container">
        <div className="tunes_empty_box"></div>
        <div className="music_first">
          <div className="music_box">
            <Music
              name="EASY COME EASY GO"
              image={EasyComeEasyGo}
              artist="https://push.fm/fl/easycomeeasygo"
              youtube="https://www.youtube.com/watch?v=DylP1fVfybM"
              color="#2F2827"
            />
          </div>
          <div className="music_box">
            <Music
              name="Bestfriend"
              image={Bestfriend}
              artist="https://song.link/in/i/1632116706"
              youtube="https://youtu.be/DcYCnUy8v5A"
              color="#3A4E5E"
            />
          </div>
        </div>

        <div className="music_middle music_box">
          <Music
            name="Lately Maybe"
            image={LatelyMaybe}
            artist="https://song.link/in/i/1601426371"
            youtube="https://youtu.be/o5AsdA_hbcc"
            color="#77654B"
          />
        </div>

        <div className="music_last music_box">
          <Music
            name="L.O.M.L"
            image={LOML}
            artist="https://song.link/in/i/1663535710"
            youtube="https://youtu.be/V01_l9pqXtw"
            color="#377764"
          />
          <Music
            name="Waitng for you"
            image={WFY}
            artist="https://song.link/in/i/1563839707"
            youtube="https://youtu.be/8yWquKyihXE"
            color="#000000"
          />
        </div>
      </div>

      <a
        href="https://open.spotify.com/track/7iKNlKU1BJtNpm0iaCRbgs?si=b636b43d6c314a69"
        target="_blank"
        rel="noreferrer">
        <div className="marquee">
          <div className="track">
            <div>
              &nbsp;&nbsp;*<span>EASY COME EASY GO</span> OUT NOW - AVAILABLE ON
              ALL STREAMING PLATFORMS* &nbsp;&nbsp;*
              <span>EASY COME EASY GO</span> OUT NOW - AVAILABLE ON ALL
              STREAMING PLATFORMS* &nbsp;&nbsp;*
              <span>EASY COME EASY GO</span> OUT NOW - AVAILABLE ON ALL
              STREAMING PLATFORMS* &nbsp;&nbsp;*
              <span>EASY COME EASY GO</span> OUT NOW - AVAILABLE ON ALL
              STREAMING PLATFORMS*
            </div>
          </div>
        </div>
      </a>
    </motion.section>
  );
}

export default Tunes;
