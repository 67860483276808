import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCXNA-xLMV4uwrq_HAITDUGjmH21B1CJyc",
  authDomain: "varun-agnihotri.firebaseapp.com",
  projectId: "varun-agnihotri",
  storageBucket: "varun-agnihotri.appspot.com",
  messagingSenderId: "69146601428",
  appId: "1:69146601428:web:c0758436ec2311eef09841",
  measurementId: "G-RT13GCYHQ3",
};

const app = firebase.initializeApp(firebaseConfig);
const db = app.firestore();

export { db };
