import "./CSS/contact.css";
import { db } from "../firebase";
import { motion } from "framer-motion";

import Socials from "./Socials";
import Popup from "./Popup";
import { useState } from "react";

function Contact() {
  const [popup, setPopup] = useState(false);

  function submitHandler(e) {
    e.preventDefault();

    setPopup(true);

    const details = document.querySelectorAll(".contact_input");
    console.log("Name :", details[0].value);
    console.log("Email :", details[1].value);

    db.collection("Users")
      .doc(details[1].value)
      .set({ name: details[0].value });

    console.log("Applied Successfully");

    details[0].value = "";
    details[1].value = "";
  }

  return (
    <motion.section
      className="contact"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.15 } }}>
      <div className="contact_bg">
        <p>
          Let's
          <br />
          Talk!!
        </p>
      </div>

      <div className="contact_container">
        <form onSubmit={submitHandler} className="contact_box">
          <p>SIGN UP TO BE THE COOLEST PERSON IN ANY&nbsp;ROOM.</p>
          <input
            type="text"
            placeholder="NAME"
            className="contact_input"
            required
          />
          <input
            type="email"
            placeholder="E-MAIL"
            className="contact_input"
            required
          />
          <button type="submit">SUBMIT</button>
        </form>
      </div>
      <Socials fillColor="#ffffff" />
      <Popup state={popup} setPopup={setPopup} />
    </motion.section>
  );
}

export default Contact;
