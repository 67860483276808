import "./CSS/home.css";

import home_cover from "./Assets/Images/home_cover.png";

import instagram from "./Assets/Images/Socials/instagram.svg";
import spotify from "./Assets/Images/Socials/spotify.svg";
import youtube from "./Assets/Images/Socials/youtube.svg";
import google from "./Assets/Images/Socials/google.svg";
import facebook from "./Assets/Images/Socials/facebook.svg";

import { motion } from "framer-motion";

function Home() {
  return (
    <motion.section
      className="home"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.15 } }}>
      <img src={home_cover} alt="background" className="home_bg" />

      <img
        src={home_cover}
        alt="EASY COME EASY GO cover"
        className="home_image"
      />
      <div className="home_details">
        <h1>“EASY COME EASY GO”</h1>
        <h1>OUT NOW!!</h1>

        <div className="home_socials">
          <a href="https://www.instagram.com/varun_agnihotri/">
            <img src={instagram} alt="instagram" />
          </a>
          <a href="https://open.spotify.com/track/7iKNlKU1BJtNpm0iaCRbgs?si=b636b43d6c314a69">
            <img src={spotify} alt="spotify" />
          </a>
          <a href="https://www.youtube.com/watch?v=DylP1fVfybM">
            <img src={youtube} alt="youtube" />
          </a>
          <a href="mailto:contact@varunagnihotri.com">
            <img src={google} alt="google" />
          </a>
          <a href="https://www.facebook.com/varunagnihotrimusic/">
            <img src={facebook} alt="facebook" />
          </a>
        </div>
      </div>
    </motion.section>
  );
}

export default Home;
