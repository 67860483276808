import "./CSS/home_main.css";
import things_tape from "./Assets/Images/things_tape.png";
import arrow from "./Assets/Images/Icons/arrow.svg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function HomeMain({ setEnter }) {
  const navigate = useNavigate();

  function handleEnter() {
    navigate("/Home");

    setEnter(true);
    const homeMain = document.querySelector(".home_main");
    homeMain.style.transform = "translateX(-100%)";

    // will optimize performance, as we don't need the component anymore once entered the home page
    setTimeout(() => {
      homeMain.style.display = "none";
    }, 750);
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleEnter();
        window.removeEventListener("keydown", handleKeyDown);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <section className="home_main">
      <div className="vimeo-wrapper">
        <iframe
          src="https://player.vimeo.com/video/860108496?background=1"
          frameborder="0"
          title="HomePageVideo"></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>

      <h1 className="home_head home_head1">VARUN</h1>
      <h1 className="home_head home_head2">AGNIHOTRI.</h1>
      <p className="home_smile">; )</p>

      <ul>
        <li>
          <a
            href="https://youtu.be/8yWquKyihXE"
            target="_blank"
            rel="noreferrer">
            WFY
          </a>
        </li>
        <li>
          <a
            href="https://youtu.be/o5AsdA_hbcc"
            target="_blank"
            rel="noreferrer">
            LM
          </a>
        </li>
        <li>
          <a
            href="https://youtu.be/DcYCnUy8v5A"
            target="_blank"
            rel="noreferrer">
            BF
          </a>
        </li>
        <li>
          <a
            href="https://youtu.be/V01_l9pqXtw"
            target="_blank"
            rel="noreferrer">
            L.O.M.L
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=DylP1fVfybM"
            target="_blank"
            rel="noreferrer">
            ECEG
          </a>
        </li>
        <li>
          <a href="https://firebasestorage.googleapis.com/v0/b/varun-agnihotri.appspot.com/o/Cassette.zip?alt=media&token=cd692940-8a46-47ad-8189-3a59704c8a5b">
            <img src={things_tape} alt="tape" />
          </a>
        </li>
      </ul>

      <button id="enter_button" onClick={handleEnter}>
        ENTER&nbsp;
        <img src={arrow} alt="arrow" />
      </button>

      <a href="https://firebasestorage.googleapis.com/v0/b/varun-agnihotri.appspot.com/o/Cassette.zip?alt=media&token=cd692940-8a46-47ad-8189-3a59704c8a5b">
        <img src={things_tape} alt="tape" className="home_tape" />
      </a>
    </section>
  );
}

export default HomeMain;
