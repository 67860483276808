import "./CSS/music.css";
import artistIcon from "./Assets/Images/Icons/artist.svg";
import youtubeIcon from "./Assets/Images/Icons/youtube.svg";

function Music({ name, image, artist, youtube, color }) {
  return (
    <div className="music">
      <img src={image} alt="LOML" className="music_image" />

      <div className="music_details" style={{ backgroundColor: color }}>
        <h1>{name}</h1>

        <div className="music_link">
          <a href={artist} target="_blank" rel="noreferrer">
            <img src={artistIcon} alt="artist" />
          </a>
          <a href={youtube} target="_blank" rel="noreferrer">
            <img src={youtubeIcon} alt="youtube" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Music;
