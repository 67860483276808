import React, { useEffect } from "react";
import "./CSS/popup.css";
import close from "./Assets/Images/Icons/close.png";

function Popup({ state, setPopup }) {
  useEffect(() => {
    if (state) {
      const popup = document.querySelector(".popup");
      const width = window.innerWidth;

      if (width > 740) {
        popup.style.right = "80px";
      } else if (width > 520) {
        popup.style.right = "40px";
        popup.style.top = "108px";
      } else {
        popup.style.right = "20px";
      }
      popup.style.opacity = "1";

      const close = document.querySelector(".popup img");

      close.addEventListener("click", () => {
        popup.style.right = "0px";
        popup.style.opacity = "0";
        setPopup(false);
      });
    }
  }, [state]);

  return (
    <div className="popup">
      <p>THANK YOU FOR SIGNING UP!</p>
      <img src={close} alt="close popup" />
    </div>
  );
}

export default Popup;
