import "./CSS/nav_bar.css";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

import hamburger from "./Assets/Images/Icons/menu.png";
import close from "./Assets/Images/Icons/close.png";

function NavBar() {
  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    const nav_menu = document.querySelector(".navbar_menu");
    const hamburger = document.querySelector(".navbar_hamburger");
    const close_icon = document.querySelector(".navbar_close");

    hamburger.addEventListener("click", () => {
      navbar.style.borderBottomLeftRadius = "0px";
      navbar.style.borderBottomRightRadius = "0px";

      nav_menu.style.display = "flex";

      setTimeout(() => {
        document.addEventListener("click", closeMenu);
        hamburger.style.display = "none";
        close_icon.style.display = "inline-block";
        navbar.style.transition = "border-radius 0.15s ease-in-out";
      }, 10);
    });

    function closeMenu() {
      navbar.style.borderBottomLeftRadius = "28px";
      navbar.style.borderBottomRightRadius = "28px";

      nav_menu.style.display = "none";
      hamburger.style.display = "inline-block";
      close_icon.style.display = "none";

      setTimeout(() => {
        navbar.style.transition = "none";
      }, 150);

      document.removeEventListener("click", closeMenu);
    }
  }, []);

  return (
    <section className="navbar">
      <NavLink to="Home" style={{ textDecoration: "none" }}>
        <h1>VARUN AGNIHOTRI</h1>
      </NavLink>

      <div className="navbar_menu">
        <NavLink className="navbar_option" to="Tunes">
          TUNES
        </NavLink>

        <NavLink className="navbar_option" to="Tour">
          TOUR
        </NavLink>

        <NavLink className="navbar_option" to="Things">
          THINGS
        </NavLink>

        <NavLink className="navbar_option" to="About">
          ME?
        </NavLink>

        <NavLink className="navbar_option" to="Contact">
          CONTACT
        </NavLink>
      </div>
      <img src={hamburger} alt="menu" className="navbar_hamburger" />
      <img src={close} alt="close" className="navbar_close" />
    </section>
  );
}

export default NavBar;
