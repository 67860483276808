import "./CSS/things.css";
import radioTape from "./Assets/Images/things_tape.png";
import folderIcon from "./Assets/Images/Icons/mac_folder.png";
import latelyMaybe from "./Assets/Images/LatelyMaybe.png";

import EPK from "./Assets/Varun_Agnihotri_EPK_2023.pdf";

import { motion } from "framer-motion";

function Things() {
  return (
    <motion.section
      className="things"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.15 } }}>
      <div className="things_bg">
        <p>THINGS</p>
      </div>

      <div className="things_box">
        <div className="things_tape">
          <a href="https://firebasestorage.googleapis.com/v0/b/varun-agnihotri.appspot.com/o/Cassette.zip?alt=media&token=cd692940-8a46-47ad-8189-3a59704c8a5b">
            <img src={radioTape} alt="tape" />
          </a>
        </div>
        <a
          href={EPK}
          download="Varun_Agnihotri_EPK_2023"
          className="things_folder">
          <img src={folderIcon} alt="folder" />
          <p>EPK</p>
        </a>
        <a
          href="https://drive.google.com/drive/folders/15t1byzTu1Cc859ARkp0ioUV0NCsz89Uu?usp=share_link"
          target="_blank"
          rel="noreferrer"
          className="things_gallery">
          <img src={latelyMaybe} alt="gallery" />
          <p>Gallery</p>
        </a>
      </div>
    </motion.section>
  );
}

export default Things;
