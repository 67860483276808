import "./CSS/tour.css";

import { motion } from "framer-motion";

function Tour() {
  return (
    <motion.section
      className="tour"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.15 } }}>
      <div className="tour_bg">
        <p>TOUR</p>
      </div>

      <div className="tour_box">
        <div className="tour_head">
          <h1>COMING</h1>
          <h1>SOON</h1>
        </div>
        <p>Meanwhile, just keep swimming&nbsp;...</p>
      </div>
    </motion.section>
  );
}

export default Tour;
