import "./CSS/about.css";
import about_pic from "./Assets/Images/about_pic.png";

import { motion } from "framer-motion";

function About() {
  return (
    <motion.section
      className="about"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.15 } }}>
      <div className="about_bg">
        <p>WHO ME?</p>
      </div>

      <div className="about_details">
        <img src={about_pic} alt="about" />
        <div>
          <h1>This is me...</h1>
          <p>
            Varun’s music is more than just a collection of songs - it is a
            testament to the transformative power of art. Drawing on his own
            experiences and those of the people around him, he creates a world
            that is both beautiful and true, a world that invites us to see the
            world in a new light.
          </p>

          <p>
            With his unique blend of jazz, pop, and R&B, Varun has captured the
            hearts of his audiences, creating music that speaks to the soul and
            moves the spirit. His poetic lyrics have become a hallmark of his
            music.
          </p>

          <p>
            Through his music, Varun tells a story of love, loss, and
            self-discovery, inviting listeners to join him on a journey of the
            heart. With tracks like "Waiting for You" and "Lately Maybe," he
            touches on the human experience with a depth and nuance that is both
            relatable and profound.
          </p>
        </div>
      </div>
    </motion.section>
  );
}

export default About;
