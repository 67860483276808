import "./App.css";
import { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// import ComingSoon from "./Components/ComingSoon";
import NavBar from "./Components/NavBar";
import HomeMain from "./Components/HomeMain";
import Home from "./Components/Home";
import Tunes from "./Components/Tunes";
import Tour from "./Components/Tour";
import Things from "./Components/Things";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Socials from "./Components/Socials";

function App() {
  const [enter, setEnter] = useState(false);

  const location = useLocation();

  return (
    <>
      <HomeMain setEnter={setEnter} />
      {enter && (
        <>
          <NavBar />
          <Socials fillColor="#000000" />
        </>
      )}

      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<HomeMain setEnter={setEnter} />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Tunes" element={<Tunes />} />
          <Route path="/Tour" element={<Tour />} />
          <Route path="/Things" element={<Things />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
